@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  ::before,
  ::after {
    @apply dark:border-gray-600;
  }
}

/* @import url('https://rsms.me/inter/inter.css');

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
} */

/* @font-face {
  font-family: 'Atkinson';
  font-weight: 400;
  src: local('Atkinson'),
    url(./assets/fonts/Atkinson-Hyperlegible-Regular-102a.woff2) format('woff2');
}

@font-face {
  font-family: 'Atkinson';
  font-weight: 600 900;
  src: local('Atkinson'),
    url(./assets/fonts/Atkinson-Hyperlegible-Bold-102a.woff2) format('woff2');
} */

* {
  -webkit-overflow-scrolling: touch;
}

html {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Atkinson', sans-serif;
}

body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  /* width: 100%;
  min-height: 100%;
  height: 100%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html,
body,
#root,
#root > div {
  height: 100vh;
} */

.running-blinker {
  animation: blinker 3s ease infinite;
}

@keyframes blinker {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 1;
  }
}

.rotate {
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
}
.react-switch {
  vertical-align: middle;
}

input:invalid {
  background-color: lightpink;
}
